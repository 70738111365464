import React from 'react';

import * as Styled from './spacer.styled';

export const SpacerVertical = ({ size }) => (
  <Styled.SpacerVertical size={size} />
);

export const SpacerHorizontal = ({ size }) => (
  <Styled.SpacerHorizontal size={size} />
);
