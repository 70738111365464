import { useContext } from 'react';
import { PolkadotContext } from '../contexts/polkadot_context';

const usePolkadot = () => {
  const polkadotContext = useContext(PolkadotContext);

  return polkadotContext;
};

export default usePolkadot;
