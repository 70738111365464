import styled from 'styled-components/macro';

export const SpacerVertical = styled.div`
  height: 100%;
  width: ${({ size }) => `${size}px`};
`;

export const SpacerHorizontal = styled.div`
  height: ${({ size }) => `${size}px`};
  width: 100%;
`;
